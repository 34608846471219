// import

import {RemixBrowser, useLocation, useMatches} from '@remix-run/react';
import * as Sentry from '@sentry/remix';
import {startTransition, StrictMode, useEffect} from 'react';
import {hydrateRoot} from 'react-dom/client';

import {sentryOpts} from './libs/sentry';

// config

Sentry.init({
  ...sentryOpts,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  tracePropagationTargets: [
    /^https?:\/\/localhost/u,
    /^https?:\/\/(?:api|app|w{3})\.browsercat\.com/u,
  ],

  integrations: [
    Sentry.browserTracingIntegration({
      useEffect,
      useLocation,
      useMatches,
    }),
    Sentry.replayIntegration(),
  ],
});

// run

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <RemixBrowser />
    </StrictMode>,
  );
});
