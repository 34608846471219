// import

import * as Sentry from '@sentry/remix';

import {env} from './env';

// types

type RemixOptions = Parameters<typeof Sentry.init>[0];

// vars

const environment = env.nodeEnv;
const isProd = environment === 'production';

const enabled = isProd;
const dsn = 'https://0f59448c2f2b416b967a3b70e4912a21@o4505215370133504.ingest.sentry.io/4505289685991424';

// config

const sampleRate = isProd ? 0.5 : 1;
const systemRoutes = /^\/(?:health|metrics)/u;

type SamplingContext = Parameters<Required<RemixOptions>['tracesSampler']>[0];

const sampler = (ctx: SamplingContext): number => {
  const url = new URL(ctx.request?.url ?? '', 'http://localhost');

  if (systemRoutes.test(url.pathname)) {
    return 0.001;
  }

  return sampleRate;
};

export const sentryOpts: RemixOptions = {
  dsn,
  enabled,
  environment,
  sampleRate,
  tracesSampler: sampler,
  profilesSampler: sampler,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,

  tracePropagationTargets: [
    /^https?:\/\/localhost/u,
    /^https?:\/\/(?:api|app|w{3})\.browsercat\.com/u,
  ],

  beforeSend(event) {
    return {...event, tags: {...event.tags, ...sentryTags}};
  },

  beforeSendTransaction(event) {
    return {...event, tags: {...event.tags, ...sentryTags}};
  },
};

export const sentryTags: Record<string, string> = {};

// config

Sentry.setTags(sentryTags);

// export

export {Sentry};
